import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import StarOutlinedIcon from '@material-ui/icons/StarOutlined';

import { calculatePriceWithMaxDiscount } from '../../utils/rentDiscountPriceCalculator';
import {
  CardWrapper,
  CardTitle,
  // CardImage,
  CardContainer,
  CardSubtitle,
  CardRating,
  CardRatingLabel,
  CardQuantity,
  CardStats,
  Owner,
  OwnerSubtitle,
  Tags,
  Tag,
  CardPriceWrapper,
  CardPrice,
  CardDuration,
  CardImage,
  CardImageWrapper,
} from './styles';

const carTags = {
  IMMEDIATE_BOOKING: 'Мгновенное бронирование',
  DELIVERY_CITY: 'Доставка по городу',
  DELIVERY_AIRPORT: 'Встреча в аэропорту',
  UNLIMITED_MILAGE: 'Пробег неограничен',
  UNLIMITED_MILAGE_SERVICE: 'Пробег неограничен (₽)',
  AUTOMATIC_TRANSMISSION: 'Автомат',
};

const CategoryCard = ({
  className,
  uuid,
  brand,
  model,
  productionYear,
  photoUrl,
  rating,
  rentsQty,
  reviewsQty,
  rentPricePerDay: defaultRentPrice,
  rentDuration,
  twoDaysDiscount,
  threeDaysDiscount,
  fiveDaysDiscount,
  weekDiscount,
  twoWeeksDiscount,
  monthDiscount,
  tags,
  owner: { firstName, avatar },
  withOwner,
  withDiscount,
}) => {
  const rentPricePerDay = withDiscount
    ? calculatePriceWithMaxDiscount({
        rentPricePerDay: defaultRentPrice,
        twoDaysDiscount,
        threeDaysDiscount,
        fiveDaysDiscount,
        weekDiscount,
        twoWeeksDiscount,
        monthDiscount,
      })
    : defaultRentPrice;

  return (
    <CardWrapper className={className}>
      <a href={`/app/car/card/${uuid}`}>
        <CardImageWrapper>
          <CardImage src={photoUrl} />
          <CardPriceWrapper>
            <CardPrice>от {rentPricePerDay} &#8381; </CardPrice>
            <CardDuration>/сутки</CardDuration>
          </CardPriceWrapper>
        </CardImageWrapper>
        <CardContainer marginBottom={!rentsQty && !reviewsQty}>
          <CardSubtitle>{`${brand} ${model}, ${productionYear}`}</CardSubtitle>
          {rating ? (
            <CardRating>
              <CardRatingLabel className="label">{rating.toFixed(1)}</CardRatingLabel>
              <StarOutlinedIcon className="icon" />
            </CardRating>
          ) : null}
        </CardContainer>
        {rentsQty || reviewsQty ? (
          <CardStats>
            <CardQuantity>{`поездки: ${rentsQty}`}</CardQuantity>
            <CardQuantity>{`отзывы: ${reviewsQty}`}</CardQuantity>
          </CardStats>
        ) : null}
        {withOwner && (
          <Owner>
            <Avatar alt={firstName} src={avatar} className="avatar" />
            <OwnerSubtitle>{firstName}</OwnerSubtitle>
          </Owner>
        )}
        {tags.length > 0 ? (
          <Tags>
            {tags.map((tag) => (
              <Tag>{carTags[tag]}</Tag>
            ))}
          </Tags>
        ) : null}
      </a>
    </CardWrapper>
  );
};

export default CategoryCard;
