import React, { useState, useEffect } from 'react';
import axios from 'axios';
import useTheme from '@material-ui/core/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import SwiperComponent from '../ui-kit/swiper';
import {
  defaultStartRent,
  defaultEndRent,
  startRentWithDelay,
  endRent,
  getRentDuration,
} from '../../utils/date';
import { SEARCH_CAR_URL } from '../../constants/endpoints';

import { Title } from '../../styledComponents';
import CategoryCard from './categoryCard';
import { CategorySliderWrapper } from './styles';

export const CarSlider = ({
  searchQuery,
  rentDelay = 30,
  rentDuration: definedRentDuration,
  immediatelyStart,
  title,
  withOwner = false,
  withDiscount = false,
}) => {
  const [cars, setCars] = useState([]);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const dateStart = immediatelyStart ? defaultStartRent() : startRentWithDelay(rentDelay);
  const dateEnd = definedRentDuration
    ? endRent(dateStart, definedRentDuration)
    : defaultEndRent(dateStart);
  const rentDuration = definedRentDuration || getRentDuration(dateEnd, dateStart);

  // TODO move dates queries in parent component
  const getCars = async ({ dateStart, dateEnd }) => {
    try {
      const url = `${SEARCH_CAR_URL}?${searchQuery}&dateStart=${dateStart}:00.000Z&dateEnd=${dateEnd}:00.000Z`;
      const response = await axios.get(url);
      const newCars = response.data.data;
      setCars(newCars);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getCars({ dateStart, dateEnd });
  }, []);

  return (
    <CategorySliderWrapper>
      {title && <Title>{title}</Title>}
      {Boolean(cars.length) && (
        <SwiperComponent
          name="cars-swiper"
          options={{ spaceBetween: isMobile ? 36 : 30 }}
          height="350px"
        >
          {cars.map((car) => (
            <CategoryCard
              className="swiper-slide"
              {...car}
              withOwner={withOwner}
              withDiscount={withDiscount}
              rentDuration={rentDuration}
            />
          ))}
        </SwiperComponent>
      )}
    </CategorySliderWrapper>
  );
};
