import React from 'react';
import useTheme from '@material-ui/core/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import AppLinksBlock from '../ui-kit/appLinks';
import { defaultEndRent, startRentWithDelay } from '../../utils/date';

import { Colored } from '../../styledComponents';
import { Container, TextWrap, Text, Title, KeyText, ImgWrap } from './styles';

export const WhoWeAreBlock = ({ btnLink }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const dateStart = startRentWithDelay(30);
  const dateEnd = defaultEndRent(dateStart);
  const link =
    btnLink ||
    `/app/search?location=38.971526%2C45.024359&address=Краснодар&dateStart=${encodeURIComponent(
      dateStart,
    )}&dateEnd=${encodeURIComponent(dateEnd)}`;

  return (
    <Container>
      {isMobile && (
        <Title>
          Что такое <Colored color="#066BD6">Getarent</Colored>?
        </Title>
      )}
      <TextWrap>
        {!isMobile && (
          <Title>
            Что такое <Colored color="#066BD6">Getarent</Colored>?
          </Title>
        )}
        <Text>
          Getarent - это мобильное приложение, которое{' '}
          <KeyText>помогает водителям просто и удобно найти и забронировать автомобиль</KeyText> для
          поездок длительностью от 1 дня. Машины предоставляют проверенные и надежные владельцы: они
          передают ключи возле своего дома или организовывают доставку по адресу клиента и
          предоставляют высокий уровень сервиса.
          <br /> <br />
          Выбрать автомобиль можно по настоящим фотографиям, рейтингу и отзывам от других водителей.
          <KeyText> Попробуйте Getarent. Это удобно.</KeyText>
        </Text>
        <AppLinksBlock />
      </TextWrap>
      <ImgWrap />
    </Container>
  );
};

export default WhoWeAreBlock;
