import styled from 'styled-components';
import { Title } from '../../../styledComponents';
import { breakpoints } from '../../../constants/breakpoints';

export const BoldText = styled.p`
  @media ${breakpoints.mobile} {
    font-weight: 700;
    font-size: 18px;
    line-height: 140%;
    color: #222222;
  }

  @media ${breakpoints.laptop} {
    font-size: 24px;
  }
`;

export const Text = styled.p`
  @media ${breakpoints.mobile} {
    font-weight: 400;
    font-size: 14px;
    line-height: 160%;
    color: #222222;
  }

  @media ${breakpoints.laptop} {
    font-size: 16px;
    line-height: 150%;
  }
`;

export const Image = styled.div`
  @media ${breakpoints.mobile} {
    width: 100%;
    height: 400px;
    background: ${(props) => `url(${props.mobileImage}) no-repeat`};
    background-size: cover;
    border-radius: 4px;
    margin-bottom: 40px;
  }

  @media ${breakpoints.laptop} {
    width: 420px;
    height: 524px;
    background: ${(props) => `url(${props.laptopImage}) no-repeat`};
    margin: 0 140px 0 0;
    background-size: contain;
  }
`;

export const TravalingTitle = styled.h4`
  @media ${breakpoints.mobile} {
    font-weight: 700;
    font-size: 18px;
    line-height: 140%;
    color: #222222;
  }

  @media ${breakpoints.laptop} {
    font-size: 24px;
  }
`;

export const TravalingText = styled.p`
  @media ${breakpoints.mobile} {
    font-weight: 400;
    font-size: 14px;
    line-height: 160%;
    color: #222222;
  }

  @media ${breakpoints.laptop} {
    font-size: 16px;
    line-height: 150%;
  }
`;

export const TravalingCard = styled.div`
  @media ${breakpoints.mobile} {
    & {
      margin-bottom: 24px;
    }

    ${TravalingTitle} {
      margin-bottom: 8px;
    }
  }

  @media ${breakpoints.laptop} {
    & {
      width: calc((100% - 252px) / 3);
      margin-bottom: 40px;
      margin-right: 96px;

      &:nth-child(3n) {
        margin-right: 60px;
      }
    }

    ${TravalingTitle} {
      margin-bottom: 16px;
    }
  }
`;

export const TravelingMapWrapper = styled.div`
  @media ${breakpoints.mobile} {
    & {
      width: 100%;
      padding: ${(props) => (props.withoutMarginTop ? '0  15px 60px ' : '60px 15px')};
    }

    ${Title} {
      text-align: center;
      margin-bottom: 40px;
    }

    ${BoldText} {
      margin-bottom: 24px;
    }

    ${Text} {
      margin-bottom: 40px;
    }

    .btn {
      width: 100%;
      max-width: 330px;
      margin-bottom: 60px;
    }

    .collapse-action {
      margin: 16px auto 0;
      display: block;
      padding: 14px 26px;
    }
  }

  @media ${breakpoints.tablet} {
    & {
      padding: ${(props) => (props.withoutMarginTop ? '0  24px 60px ' : '60px 24px')};
    }

    .btn {
      width: auto;
      max-width: none;
    }
  }

  @media ${breakpoints.laptop} {
    & {
      padding: ${(props) => (props.withoutMarginTop ? '0  36px 100px ' : '100px 36px')};
    }

    ${Title} {
      text-align: left;
      margin: 60px 0 40px;
    }

    ${BoldText} {
      margin-bottom: 16px;
    }

    .btn {
      margin-bottom: 0;
    }

    .collapse-action {
      margin: 20px auto 0;
    }
  }

  @media ${breakpoints.desktop} {
    & {
      padding: ${(props) => (props.withoutMarginTop ? '0  54px 100px ' : '100px 54px')};
      max-width: 1440px;
    }
  }
`;

export const CardsContainer = styled.div`
  @media ${breakpoints.laptop} {
    display: flex;
    flex-wrap: wrap;
  }
`;

export const Container = styled.div`
  @media ${breakpoints.laptop} {
    display: flex;
    margin-bottom: 100px;
  }
`;

export const Wrapper = styled.div`
  @media ${breakpoints.laptop} {
    width: calc(100% - 560px);
    max-width: 721px;
  }
`;
