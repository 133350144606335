import React from 'react';

import { Title } from '../../styledComponents';
import { CarSlider } from '../carSlider';
import { MapLinkButton } from '../mapLinkButton';
import { Wrapper } from './styles';

export const CarsAvailableToday = ({
  title = '',
  LTR,
  immediatelyStart,
  rentDelay,
  rentDuration,
  withDiscount,
  withOwner,
  searchQuery,
}) => {
  return (
    <Wrapper LTR>
      <Title>{title}</Title>
      <CarSlider
        searchQuery={searchQuery || 'location=38.971526%2C45.024359&address=Краснодар'}
        withOwner={withOwner}
        withDiscount={withDiscount}
        rentDelay={rentDelay}
        rentDuration={rentDuration}
      />
      {/* <MapLinkButton
        immediatelyStart={immediatelyStart}
        rentDelay={rentDelay}
        rentDuration={rentDuration}
      /> */}
    </Wrapper>
  );
};
