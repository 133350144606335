const normalizePrice = (price, discount) => Math.ceil(price - (price * discount) / 100);

export const calculatePrice = ({
  rentDuration,
  rentPricePerDay,
  twoDaysDiscount,
  threeDaysDiscount,
  fiveDaysDiscount,
  weekDiscount,
  twoWeeksDiscount,
  monthDiscount,
}) => {
  let discount = 0;

  if (rentDuration > 1) {
    discount = twoDaysDiscount || discount;
  }
  if (rentDuration >= 3) {
    discount = threeDaysDiscount || discount;
  }
  if (rentDuration >= 5) {
    discount = fiveDaysDiscount || discount;
  }
  if (rentDuration >= 7) {
    discount = weekDiscount || discount;
  }
  if (rentDuration >= 14) {
    discount = twoWeeksDiscount || discount;
  }
  if (rentDuration >= 30) {
    discount = monthDiscount || discount;
  }

  return normalizePrice(rentPricePerDay, discount);
};

export const calculatePriceWithMaxDiscount = ({
  rentPricePerDay,
  twoDaysDiscount,
  threeDaysDiscount,
  fiveDaysDiscount,
  weekDiscount,
  twoWeeksDiscount,
  monthDiscount,
}) => {
  let discount = 0;

  if (twoDaysDiscount) {
    discount = twoDaysDiscount;
  }
  if (threeDaysDiscount) {
    discount = threeDaysDiscount;
  }
  if (fiveDaysDiscount) {
    discount = fiveDaysDiscount;
  }
  if (weekDiscount) {
    discount = weekDiscount;
  }
  if (twoWeeksDiscount) {
    discount = twoWeeksDiscount;
  }
  if (monthDiscount) {
    discount = monthDiscount;
  }

  return normalizePrice(rentPricePerDay, discount);
};
