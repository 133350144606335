import React, { useState } from 'react';
import useTheme from '@material-ui/core/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Button from '@material-ui/core/Button';
import Collapse from '@material-ui/core/Collapse';

import { track } from '../../../seo';
import MobileImage from '../../../assets/images/170.jpg';
import LaptopImage from '../../../assets/images/171.jpg';
import { Title } from '../../../styledComponents';

import {
  CardsContainer,
  Container,
  TravelingMapWrapper,
  TravalingCard,
  TravalingTitle,
  TravalingText,
  BoldText,
  Text,
  Image,
  Wrapper,
} from './styles';

const TravelingMap = ({ titleContent, boldText, text, content, link, seo, withoutMarginTop }) => {
  const [show, setShow] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const handleChange = () => {
    setShow((prev) => !prev);
  };

  const showElementsCount = isMobile ? 3 : 6;
  const showElements = content.slice(0, showElementsCount);
  const hideElements = content.slice(showElementsCount, content.length);

  return (
    <TravelingMapWrapper withoutMarginTop>
      {isMobile ? (
        <>
          <Title>{titleContent}</Title>
          <BoldText>{boldText}</BoldText>
          <Image mobileImage={MobileImage} laptopImage={LaptopImage} />
          <Text>{text}</Text>
          <a
            href={link}
            title="Забронировать автомобиль"
            onClick={() => {
              if (seo) {
                track(seo);
              }
            }}
          >
            <Button variant="contained" color="secondary" className="btn">
              Забронировать автомобиль
            </Button>
          </a>
        </>
      ) : (
        <Container>
          <Image mobileImage={MobileImage} laptopImage={LaptopImage} />
          <Wrapper>
            <Title>{titleContent}</Title>
            <BoldText>{boldText}</BoldText>
            <Text>{text}</Text>
            <a
              href={link}
              title="Забронировать автомобиль"
              onClick={() => {
                if (seo) {
                  track(seo);
                }
              }}
            >
              <Button variant="contained" color="secondary" className="btn">
                Забронировать автомобиль
              </Button>
            </a>
          </Wrapper>
        </Container>
      )}
      <CardsContainer>
        {showElements.map(({ key, title, text }) => (
          <TravalingCard key={key}>
            <TravalingTitle>{title}</TravalingTitle>
            <TravalingText>{text}</TravalingText>
          </TravalingCard>
        ))}
      </CardsContainer>
      <Collapse in={show}>
        <CardsContainer>
          {hideElements.map(({ key, title, text }) => (
            <TravalingCard key={key}>
              <TravalingTitle>{title}</TravalingTitle>
              <TravalingText>{text}</TravalingText>
            </TravalingCard>
          ))}
        </CardsContainer>
      </Collapse>
      <Button variant="outlined" className="collapse-action" onClick={handleChange}>
        {show ? 'Скрыть' : 'Показать больше городов'}
      </Button>
    </TravelingMapWrapper>
  );
};

export default TravelingMap;
