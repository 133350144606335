import styled from 'styled-components';

import { breakpoints } from '../../constants/breakpoints';
import { Title } from '../../styledComponents';
import { CategorySliderWrapper } from '../carSlider/styles';

export const Wrapper = styled.div`
  @media ${breakpoints.mobile} {
    & {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      max-width: 1440px;
      // padding: 0 15px;
    }

    ${CategorySliderWrapper} {
      margin-bottom: 30px;
      padding: 0;
    }

    ${Title} {
      margin-bottom: 30px;
      padding: 0;
      text-align: ${({ LTR }) => (LTR ? 'center' : 'left')};
    }
  }

  @media ${breakpoints.tablet} {
    ${CategorySliderWrapper} {
      margin-bottom: 40px;
    }

    ${Title} {
      margin-bottom: 40px;
    }
  }

  @media ${breakpoints.laptop} {
    & {
      padding: 0 54px;
    }
  }
`;
