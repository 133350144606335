import React from 'react';
import styled from 'styled-components';
import Button from '@material-ui/core/Button';

import { defaultEndRent, endRent, defaultStartRent, startRentWithDelay } from '../../utils/date';

const LinkButton = styled(Button)`
  height: 45px;
`;

export const MapLinkButton = ({ btnLink, immediatelyStart, rentDelay = 30, rentDuration }) => {
  const dateStart = immediatelyStart ? defaultStartRent() : startRentWithDelay(rentDelay);
  const dateEnd = rentDuration ? endRent(dateStart, rentDuration) : defaultEndRent(dateStart);
  const link =
    btnLink ||
    `/app/search?bounds=39.21432909410287%2C45.262907255865954%2C38.82733569599969%2C44.736904200467755&address=Краснодар&dateStart=${encodeURIComponent(
      dateStart,
    )}&dateEnd=${encodeURIComponent(dateEnd)}&map=true`;

  return (
    <a href={link} title="Показать на карте">
      <LinkButton variant="contained" color="secondary">
        Показать на карте
      </LinkButton>
    </a>
  );
};
