import styled from 'styled-components';
import Button from '@material-ui/core/Button';

// import {AppLinksWrapper} '../ui-kit/appLinks/styles';
import WhoWeAreSmartImage from '../../assets/images/who-we-are-smart.webp';
import { breakpoints } from '../../constants/breakpoints';

export const Container = styled.div`
  @media ${breakpoints.mobile} {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 1440px;
    padding: 0 15px;
  }

  @media ${breakpoints.laptop} {
    flex-direction: row;
    padding: 0 54px;
  }
`;

export const TextWrap = styled.div`
  @media ${breakpoints.mobile} {
    display: flex;
    flex-direction: column;
    align-items: ${({ LTR }) => (LTR ? 'center' : 'flex-start')};
    order: 1;
    width: 100%;
  }

  @media ${breakpoints.laptop} {
    order: 0;
    width: 40%;
    margin-right: 150px;
  }
`;

export const Text = styled.p`
  @media ${breakpoints.mobile} {
    margin-bottom: 40px;
  }
`;

export const Title = styled.p`
  @media ${breakpoints.mobile} {
    font-family: 'Open Sans';
    font-size: 24px;
    font-weight: 700;
    line-height: 45px;
    letter-spacing: 0px;
    text-align: center;
  }

  @media ${breakpoints.tablet} {
    margin-bottom: 40px;
    font-size: 32px;
  }

  @media ${breakpoints.laptop} {
    text-align: left;
  }
`;

export const KeyText = styled.span`
  @media ${breakpoints.mobile} {
    color: ${(props) => props.color || '#066bd6'};
    font-weight: 600;
  }
`;

export const ImgWrap = styled.div`
  @media ${breakpoints.mobile} {
    display: flex;
    order: 0;
    width: 100%;
    height: 450px;
    background: url(${WhoWeAreSmartImage}) no-repeat;
    background-position: center;
    background-size: contain;
  }

  @media ${breakpoints.tablet} {
    margin-bottom: 40px;
  }

  @media ${breakpoints.laptop} {
    order: 1;
    width: 48%;
    height: 535px;
    margin-bottom: 0;
  }
`;

export const LinkButton = styled(Button)`
  display: flex;
  margin: 0 auto;
  height: 45px;
`;
