import React, { useEffect, useState } from 'react';
import { isBrowser } from '../utils/isBrowser';
import AppLinksBlock from '../components/homepage/appLinksBlock';
import Layout from '../components/layout';
import InsuranceBlock from '../components/safety/insurance';
import TravelingMap from '../components/cities/travelingMap';
import ReviewBlock from '../components/reviewBlock';
import Partners from '../components/partners';
import SEO from '../components/seo';
import SearchWidgetWithout2GIS from '../components/homepage/searchWidget/WidgetWithout2GIS';
import Faq from '../components/faqNew';
import { WhoWeAreBlock } from '../components/whoWeAre';
import { CarsAvailableToday } from '../components/carsAvailableToday';
import { defaultEndRent, defaultStartRent } from '../utils/date';
import { googleTracks, yandexTracks } from '../seo';

import krasnodarTravelContent from '../assets/data/krasnodar-travel-content.json';
import faqContent from '../assets/data/faq-guest-content.json';
import { serviceAdvantagesContent } from '../assets/data/service-advantages-content';

import { BackgroundWrapper, Colored, Company } from '../styledComponents';

const travalingTitleContent = (
  <>
    <Colored color="#066bd6">Куда поехать</Colored> из Краснодара?
  </>
);

const travalingBoldText =
  'Путешествие по Югу России на автомобиле – прекрасная возможность поближе познакомиться с уникальной природой Черноморского побережья и его выдающимися достопримечательностями.';

const travalingText =
  'Выбрав одно из 8 направлений для отдыха, вы искренне полюбите эти места с великим культурным и историческим прошлым.';

const travalingLink = '/app/search?location=38.971526%2C45.024359&address=Краснодар';

export default () => {
  const [dateQueries, setDataQueries] = useState('');

  useEffect(() => {
    const dateStart = defaultStartRent();
    const encodedDateStart = encodeURIComponent(dateStart);
    const dateEnd = defaultEndRent(dateStart);
    const encodedDateEnd = encodeURIComponent(dateEnd);
    setDataQueries(`&dateStart=${encodedDateStart}&dateEnd=${encodedDateEnd}`);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [isBrowser]);

  return (
    <Layout searchFilter="?location=38.971526%2C45.024359&address=Краснодар">
      <SEO
        title="Аренда автомбиля от частных лиц в Краснодаре"
        description="Бронируйте автомобиль онлайн за 5 минут без залога с включенным КАСКО для путешествий и поездок по городу"
      />
      <BackgroundWrapper>
        <SearchWidgetWithout2GIS
          title={
            <>
              Поиск машины <Company>в аренду от 1 дня</Company>
            </>
          }
          text="Здесь люди арендуют машины у людей"
          initialAddress="Краснодар"
          breadcrumbs="Краснодар"
          seo={[yandexTracks.krasnodarFindCar, googleTracks.krasnodarFindCar]}
        />
      </BackgroundWrapper>
      <BackgroundWrapper marginTop marginBottom>
        <CarsAvailableToday
          title="Эти машины доступны в Краснодаре"
          rentDelay={60}
          rentDuration={14}
          withDiscount
        />
      </BackgroundWrapper>
      <BackgroundWrapper>
        <ReviewBlock withoutMarginTop titleText="Отзывы о Getarent в Краснодаре" />
      </BackgroundWrapper>
      {/* <BackgroundWrapper>
        <CarsCategorySlider
          titleText={
            <>
              Найдите <Colored color="#066bd6">нужную марку</Colored> автомобиля
            </>
          }
          content={carsBrandContent}
        />
      </BackgroundWrapper> */}
      {/* <BackgroundWrapper>
        <CarsCategorySlider
          titleText={
            <>
              Найдите автомобиль в <Colored color="#066bd6">категориях</Colored>
            </>
          }
          content={carsCategoryContent}
        />
      </BackgroundWrapper> */}
      <BackgroundWrapper marginBottom>
        <WhoWeAreBlock />
      </BackgroundWrapper>
      <BackgroundWrapper marginBottom>
        <AppLinksBlock title="Установите мобильное приложение, там удобнее" />
      </BackgroundWrapper>
      <BackgroundWrapper marginBottom>
        <InsuranceBlock
          title={
            <>
              <Colored color="#066BD6">Getarent</Colored> - это аренда автомобиля по-новому
            </>
          }
          content={serviceAdvantagesContent}
          button
          LTR
        />
      </BackgroundWrapper>
      <BackgroundWrapper background="#F1F6FD">
        <Faq
          content={faqContent}
          link="https://help.getarent.ru?utm_source=car-rental-income&utm_medium=pereiti-centr-pomoschi"
          title="Остались вопросы?"
        />
      </BackgroundWrapper>
      <BackgroundWrapper>
        <Partners LTR />
      </BackgroundWrapper>
      <BackgroundWrapper>
        <TravelingMap
          link={`${travalingLink}&${dateQueries}`}
          content={krasnodarTravelContent}
          text={travalingText}
          boldText={travalingBoldText}
          titleContent={travalingTitleContent}
          seo={[yandexTracks.krasnodarBookCar, googleTracks.krasnodarBookCar]}
        />
      </BackgroundWrapper>
    </Layout>
  );
};
